import { useState } from "react"
import styled from "styled-components"

import { IconButton, Popover, PopoverProps } from "@material-ui/core"
import { useTimeout } from "usehooks-ts"

import { useFetchApiClientSecret } from "src/data/apiClients/apiClientQueries"
import { IApiClient } from "src/data/apiClients/apiClientTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { CopyBox } from "src/ui/CopyText/CopyBox"
import { GridTable } from "src/ui/GridTable/GridTable"
import { MoreButton } from "src/ui/GridTable/MoreButton"
import CopyIcon from "src/ui/icons/copy.svg"
import ExpandDownIcon from "src/ui/icons/expand-down.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MSkeleton } from "src/ui/MSkeleton/MSkeleton"
import { MText } from "src/ui/MText"
import { MTooltip } from "src/ui/MTooltip/MTooltip"
import { spacing } from "src/ui/spacing"

export function ApiClientsTable({
  clients,
  onEdit,
  onDelete,
}: {
  clients: IApiClient[]
  onEdit: (client: IApiClient) => void
  onDelete: (client: IApiClient) => void
}) {
  const { t, langKeys } = useTranslate()

  const [selectedSecret, setSelectedSecret] = useState<{
    anchor: HTMLElement
    clientId: IApiClient["client_id"]
  }>()

  const [selectedRedirecUris, setSelectedRedirectUris] = useState<{
    anchor: HTMLElement
    redirectUris: IApiClient["authorization_code_grant"]["redirect_uris"]
  }>()

  const [openClientIdCopyTooltip, setOpenTooltip] = useState<{
    timeout: number
    clientId: IApiClient["client_id"]
  }>()

  useTimeout(() => {
    setOpenTooltip(undefined)
  }, openClientIdCopyTooltip?.timeout ?? null)

  function handleRowClick(index: number) {
    onEdit(getClientByIndex(index))
  }

  function getClientByIndex(index: number): IApiClient {
    const client = clients[index]

    if (!client) {
      throw Error("Client was undefined")
    }

    return client
  }

  async function handleCopyClientIdClick(clientId: IApiClient["client_id"]) {
    await navigator.clipboard.writeText(clientId)
    setOpenTooltip({ timeout: 2000, clientId })
  }

  const rows = clients.map((client) => (
    <>
      {/** Name */}
      <InternalLink to={Routes.ApiClientsEdit.location(client.client_id)}>
        {client.name}
      </InternalLink>

      {/** Client Id */}
      <ClientIdBox>
        <MText variant="body">{client.client_id}</MText>

        <MTooltip
          title={"Copied!"}
          open={openClientIdCopyTooltip?.clientId === client.client_id}
        >
          <IconButton
            size="small"
            onClick={(event) => {
              event.stopPropagation()
              handleCopyClientIdClick(client.client_id)
            }}
          >
            <CopyIcon />
          </IconButton>
        </MTooltip>
      </ClientIdBox>

      {/** Client Secret */}
      <div>
        <TextBox>
          <MText variant="body">••••••••••••••</MText>

          <IconButton
            size="medium"
            onClick={(event) => {
              event.stopPropagation()

              setSelectedSecret({
                anchor: event.currentTarget,
                clientId: client.client_id,
              })
            }}
          >
            <ExpandDownIcon height={10} />
          </IconButton>
        </TextBox>
      </div>

      {/** Redirect URI */}
      <div>
        <TextBox>
          <MText variant="body">
            {client.authorization_code_grant.redirect_uris[0]}
          </MText>

          {client.authorization_code_grant.redirect_uris.length > 1 && (
            <IconButton
              size="medium"
              onClick={(event) => {
                event.stopPropagation()
                setSelectedRedirectUris({
                  anchor: event.currentTarget,
                  redirectUris: client.authorization_code_grant.redirect_uris,
                })
              }}
            >
              <ExpandDownIcon height={10} />
            </IconButton>
          )}
        </TextBox>
      </div>

      {/** More */}
      <div>
        <MoreButton
          onDelete={() => onDelete(client)}
          onEdit={() => onEdit(client)}
        />
      </div>
    </>
  ))

  return (
    <div>
      <GridTable
        header={[
          <div key={"client"}>{t(langKeys.api_clients_client)}</div>,
          <div key={"client-id"}>{t(langKeys.api_clients_client_id)}</div>,
          <div key={"client-secret"}>
            {t(langKeys.api_clients_client_secret)}
          </div>,
          <div key={"client-redirect-url"}>
            {t(langKeys.api_clients_redirect_uri)}
          </div>,
          <div key={"misc"}></div>,
        ]}
        rows={rows}
        templateColumns="1fr 1fr 1fr 1fr auto"
        useFallbackResponsiveMode
        onRowClick={handleRowClick}
      />

      {selectedSecret && (
        <SecretPopover
          anchorEl={selectedSecret.anchor}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={true}
          onClose={() => setSelectedSecret(undefined)}
          clientId={selectedSecret.clientId}
        />
      )}

      <Popover
        anchorEl={selectedRedirecUris?.anchor}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={!!selectedRedirecUris}
        onClose={() => setSelectedRedirectUris(undefined)}
      >
        <PopoverBox>
          <RedirectsList>
            {selectedRedirecUris?.redirectUris.map((redirectUri, index) => (
              <li key={`${redirectUri}-${index}`}>
                <MText variant="bodyS" color="secondary">
                  {" "}
                  {redirectUri}
                </MText>
              </li>
            ))}
          </RedirectsList>
        </PopoverBox>
      </Popover>
    </div>
  )
}

export function SecretPopover({
  clientId,
  ...popOverProps
}: Omit<PopoverProps, "children"> & { clientId: string }) {
  const fetchApiClientSecret = useFetchApiClientSecret({ clientId })

  const clientSecret = fetchApiClientSecret.data?.client_secret

  return (
    <Popover {...popOverProps}>
      <PopoverBox>
        {!clientSecret ? (
          <MSkeleton variant="rect" />
        ) : (
          <CopyBox text={clientSecret} />
        )}
      </PopoverBox>
    </Popover>
  )
}

const TextBox = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.XS2};
`

const PopoverBox = styled.div`
  padding: ${spacing.S};
`

const RedirectsList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
`

const ClientIdBox = styled.div`
  flex-direction: row;
  align-items: center;
  gap: ${spacing.S};
  justify-content: flex-start;
  white-space: nowrap;
  display: flex;
`
